<template>
  <div>
    <!-- Video Player (Only Shows When a Video is Selected) -->
    <v-row v-if="selectedVideo" class="position-relative">
      <v-col cols="10" class="position-relative">
        <!-- Close Button -->
        <v-btn icon class="close-button" @click="selectedVideo = null">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>

        <video-player :options="videoOptions"></video-player>
      </v-col>
    </v-row>

    <!-- Video Thumbnails in a Grid -->
    <v-container>
      <v-row>
        <v-col
          v-for="(item, i) in videoDetail"
          :key="i"
          cols="12"
          sm="6"
          md="2"
          class="d-flex flex-column align-center"
        >
          <img
            :src="item.thumbnail"
            alt="Thumbnail"
            width="100%"
            style="cursor: pointer; border-radius: 10px"
            @click="selectVideo(item)"
          />
          <div class="text-center font-weight-bold mt-2">
            WizFit Workout {{ i + 1 }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
/* Close button styling */
.close-button[data-v-0e0e3678] {
  position: absolute;
  z-index: 10;
  left: 56%;
  color: white !important;
}
.v-application .red--text {
  color: white !important;
  caret-color: #ffffff !important;
}
</style>

<script>
export default {
  name: "VideoSectionPeteacher",
  components: {
    videoPlayer: () => import("@/components/VideoPlayer/VideoPlayerPeTeacher"),
  },
  data() {
    return {
      selectedVideo: null,
      videoDetail: [
        {
          video_url: "https://video.harlemwizardsinabox.com/wizfit1.mp4",
          thumbnail:
            "https://harlemwizardsinabox-prod-bucket.s3.amazonaws.com/media/wizfit_video/thumbnail/welness_1_6gqwAvs.jpg",
        },
        {
          video_url: "https://video.harlemwizardsinabox.com/wizfit2.mp4",
          thumbnail:
            "https://harlemwizardsinabox-prod-bucket.s3.amazonaws.com/media/wizfit_video/thumbnail/200915_WizFit_Video_Assets-03_1_TPqytox.jpg",
        },
        {
          video_url: "https://video.harlemwizardsinabox.com/wizfit3.mp4",
          thumbnail:
            "https://harlemwizardsinabox-prod-bucket.s3.amazonaws.com/media/wizfit_video/thumbnail/200915_WizFit_Video_Assets-04_1_RrdmKS4.jpg",
        },
        {
          video_url: "https://video.harlemwizardsinabox.com/wizfit4.mp4",
          thumbnail:
            "https://harlemwizardsinabox-prod-bucket.s3.amazonaws.com/media/wizfit_video/thumbnail/200915_WizFit_Video_Assets-05_1_6Ah5wno.jpg",
        },
        {
          video_url: "https://video.harlemwizardsinabox.com/wizfit5.mp4",
          thumbnail:
            "https://harlemwizardsinabox-prod-bucket.s3.amazonaws.com/media/wizfit_video/thumbnail/200915_WizFit_Video_Assets-06_1_YiM5kNz.jpg",
        },
        {
          video_url: "https://video.harlemwizardsinabox.com/wizfit6.mp4",
          thumbnail:
            "https://harlemwizardsinabox-prod-bucket.s3.amazonaws.com/media/wizfit_video/thumbnail/200915_WizFit_Video_Assets-07_unU5Frc_obvGfzv.jpg",
        },
      ],
      videoOptions: {
        autoplay: true,
        controls: true,
        width: "950",
        poster: "",
        sources: [
          {
            src: "",
            type: "video/mp4",
          },
        ],
      },
    };
  },
  methods: {
    selectVideo(item) {
      this.selectedVideo = item.video_url;
      this.videoOptions = {
        autoplay: true,
        controls: true,
        width: "950",
        poster: item.thumbnail,
        sources: [{ src: item.video_url, type: "video/mp4" }],
      };
    },
  },
};
</script>
